import * as React from "react";

function IconBxsQuoteRight(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="red"
      height="3em"
      width="3em"
      {...props}
    >
      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 00-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 001 1h3.078a2.89 2.89 0 01-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 00-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 001 1h3.078a2.89 2.89 0 01-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z" />
    </svg>
  );
}

export default IconBxsQuoteRight;
