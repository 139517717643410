import * as React from "react";

function IconBxsQuoteLeft(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="red"
      height="3em"
      width="3em"
      {...props}
    >
      <path d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 006.925 10H10a1 1 0 011 1v7c0 1.103-.897 2-2 2H3a1 1 0 01-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 01-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0017.925 10H21a1 1 0 011 1v7c0 1.103-.897 2-2 2z" />
    </svg>
  );
}

export default IconBxsQuoteLeft;
